<template>
  <div>
    <ui-title
      :type="titleTag"
      :is-paragraph="isParagraph"
      :data-test="dataTest || null"
    >
      <img v-if="showPrefixIcon" src="@/assets/images/icons/svg/tilde.svg" />
      <slot v-if="!title" />
      <template v-else>
        {{ title }}
      </template>
    </ui-title>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { UiTitle } from '~/components/atoms'

export default {
  components: {
    UiTitle,
  },
  props: {
    type: VueTypes.oneOf(['primary', 'secondary']).def('primary'),
    title: VueTypes.string,
    isParagraph: VueTypes.bool.def(false),
    hasPrefixIcon: VueTypes.bool.def(false),
    dataTest: VueTypes.string.def(''),
  },
  computed: {
    titleTag() {
      return this.type === 'secondary' ? 'h2' : 'h1'
    },
    hasTitle() {
      return this.title || this.$slots.default
    },
    showPrefixIcon() {
      return this.hasPrefixIcon && this.hasTitle
    },
  },
}
</script>
